$("[data-modal-target]").on("click", function (e) {
  const modalIid = $(this).data("modal-target")
  const $modal = $(modalIid)
  $modal.css({display: "flex"})
  $modal.on("click", function (e) {
    if ($(e.target).is(modalIid)) {
      $modal.css({display: "none"})
    }
  })
  $(document).on("keyup", function (e) {
    if (e.keyCode === 27) {
      $modal.css({display: "none"})
    }
  })
})
