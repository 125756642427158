import {dateRangeLocales} from "@js/settings"
import getLanguage from "@js/utils/get_language"
import moment from "moment"

(() => {
  if (!document.getElementsByClassName("js-files-init").length) {
    return
  }
  const $dateInput = $(".js-download-dateinput")
  if (!$dateInput.length) {
    return
  }
  const availableDates = JSON.parse(document.getElementById("available_dates").textContent).map(date => moment(date).format("YYYY.MM.DD")).sort()
  $dateInput.daterangepicker({
    locale: dateRangeLocales[getLanguage()],
    singleDatePicker: true,
    autoApply: true,
    autoUpdateInput: true,
    showDropdowns: true,
    minDate: moment(availableDates[0], "YYYY.MM.DD").toDate(),
    maxDate: moment(availableDates.reverse()[0], "YYYY.MM.DD").toDate(),
    isInvalidDate: function (d) {
      return availableDates.indexOf(moment(d).format("YYYY.MM.DD")) === -1
    },
  })
  $(".js-file-download-btn").on("click", function () {
    const dateStr = moment($(".js-download-dateinput").val(), "DD.MM.YYYY").format("DDMMYY")
    window.location.href = $(this).data("download-url-pattern").replace("{date}", dateStr)
  })
})()
