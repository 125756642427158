require('expose-loader?exposes=$,jQuery,jquery!jquery')
require('expose-loader?exposes=moment!moment')
require('daterangepicker')

require("./sass/index.sass")

require("./js/pages/news")
require("./js/pages/files")

require("./js/blocks/modal")
require("./js/blocks/search")
