export default function getLanguage() {
  const pathArr = window.location.pathname.split('/')
  const langArr = ['ru', 'en', 'kz'];

  if ($.inArray(pathArr[1], langArr) !== -1) {
    return pathArr[1];
  } else {
    return 'ru';
  }
}
