import getLanguage from "@js/utils/get_language"
import {dateRangeLocales} from "@js/settings"


(() => {
  if (!document.getElementsByClassName("js-news-init").length) {
    return
  }
  $("#body").on("click", "a.js-open-in-new-window", function (e) {
    e.preventDefault()
    window.open(e.target.href, "news-window", "width=675,height=600,scrollbars=1")
  })

  const $newsSearchForm = $(".js-news-search-form")
  const $newsDateRange = $(".js-news-daterange")
  const $newsSearchInput = $(".js-news-search-input")
  const $onlyHeadersCheckbox = $(".js-only-headers-checkbox")
  const $exactCheckbox = $(".js-exact-checkbox")
  const $archiveLink = $(".js-archive-link")

  $newsSearchForm.bind("submit", function (e) {
    e.preventDefault()
    _triggerSearch()
  })

  $archiveLink.on("click", function (e) {
    e.preventDefault()
    _triggerArchive()
  })

  $newsDateRange.daterangepicker({
    autoApply: true,
    autoUpdateInput: false,
    locale: dateRangeLocales[getLanguage()],
    minDate: '17.11.1993',
    maxDate: new Date(),
  })
  $newsDateRange.on('apply.daterangepicker', function (e, picker, isInitial = false) {
    $(this).val(picker.startDate.format('DD.MM.YYYY') + ' - ' + picker.endDate.format('DD.MM.YYYY'))
    if (!isInitial) {
      if ($newsSearchInput.val().trim()) {
        setTimeout(() => _triggerSearch(), 0)
      } else {
        setTimeout(() => _triggerArchive(), 0)
      }
    }
  })
  const startDate = $newsDateRange.data("start-date")
  const endDate = $newsDateRange.data("end-date")
  if (startDate && endDate) {
    $newsDateRange.data("daterangepicker").setStartDate(Date.now())
    $newsDateRange.data("daterangepicker").setEndDate(Date.now())
    $newsDateRange.trigger("apply.daterangepicker", [$newsDateRange.data("daterangepicker"), true])
  }

  function _triggerSearch() {
    const searchTerm = $newsSearchInput.val().trim()
    if (!searchTerm) {
      return
    }
    const place = $onlyHeadersCheckbox.is(":checked") ? "headersonly" : "everywhere"
    const mode = $exactCheckbox.is(":checked") ? "strict" : "normal"

    const language = getLanguage()
    let url = `/${language}/news/`
    if ($newsDateRange.val()) {
      const [start_date, end_date] = $newsDateRange.val().split("-", 2).map(date => date.trim())
      if (!moment(start_date, 'DD.MM.YYYY').isSame(Date.now(), "day")) {
        url += `${start_date}/${end_date}/`
      }
    }
    url += `search/${place}/${mode}/${searchTerm}/`
    window.location.href = url
  }

  function _triggerArchive() {
    const language = getLanguage()
    let url = `/${language}/news/`
    const [start_date, end_date] = $newsDateRange.val().split("-", 2).map(date => date.trim())
    url += `${start_date}/${end_date}/`
    window.location.href = url
  }
})()

