(() => {
  const $searchToggleBtn = $(".js-search-toggle-btn")
  const $searchFormWrapper = $(".js-search-form-wrapper")
  const $searchForm = $searchFormWrapper.find("form")
  const $searchInput = $searchForm.find("input[type=text]")
  if (!$searchToggleBtn.length) {
    return
  }
  $searchToggleBtn.on("click", function () {
    $searchFormWrapper.slideToggle(300)
    $searchInput.focus()
  })

})()
